import { PrismicRichText } from "@prismicio/react";
import { unstyledRichTextComponents } from "@up/ui";
import * as PropTypes from "prop-types";

const RichText = function ({ field }) {
  return (
    <PrismicRichText field={field} components={unstyledRichTextComponents()} />
  );
};

RichText.propTypes = {
  render: PropTypes.array,
};

export default RichText;
