import * as PropTypes from "prop-types";

const backgroundType = function (type = "solid color") {
  return type === "solid color" ? "" : "bg-image bg-" + type;
};

const SliceWrapper = function ({
  cssClass,
  children,
  slice,
  verticalPadding = true,
  wrapperClass,
  ...rest
}) {
  const klass = `${cssClass ? cssClass : ""} ${slice.primary.additional_css_classes
    ? slice.primary.additional_css_classes
    : ""
    }`;
  return (
    <div
      className={`theme-${slice.primary.theme} ${backgroundType(
        slice.primary?.background
      )} ${verticalPadding ? "slice-padding" : ""} ${slice.primary.hidden ? "hidden" : ""
        } ${wrapperClass ? wrapperClass : ""}`}
      id={slice.primary.html_id}
      data-theme={slice.primary.theme}
      {...rest}
    >
      <div className="wrapper">
        <div className={klass}>{children}</div>
      </div>
    </div>
  );
};

SliceWrapper.propTypes = {
  children: PropTypes.any,
  cssClass: PropTypes.string,
  slice: PropTypes.object.isRequired,
  verticalPadding: PropTypes.bool,
  wrapperClass: PropTypes.string,
};

export default SliceWrapper;
