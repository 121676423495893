import Head from "next/head";

export const CanonicalLink = function ({ uid }: { uid: string }) {
  let HREF = process.env["NEXT_PUBLIC_PROD_URL"] + "/";
  if (uid && uid !== "index") {
    if (uid.match("-college-credit")) {
      HREF = `${HREF}college-credit/${uid.replace("-college-credit", "")}`;
    } else {
      HREF = HREF + uid;
    }
  }

  return (
    <Head>
      <link rel="canonical" href={HREF} key="canonical" />
    </Head>
  );
};
