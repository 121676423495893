import { UPLink } from "@up/ui";

import type { LinkField } from "@prismicio/client";
import type { ReactNode } from "react";

export type CTAButtonProps = {
  children?: ReactNode;
  className?: string;
  field: LinkField;
};

const CTAButton = function ({ children, field, className = "" }) {
  const { cta_link, cta_text, cta_classes } = field;
  const isButton = cta_link?.link_type === "Any";

  const CLASS = `${cta_classes} button ${className}`;

  return (
    <>
      {(children || cta_text) && isButton && (
        <button className={CLASS}>{children ? children : cta_text}</button>
      )}
      {(children || cta_text) && !isButton && (
        <UPLink className={CLASS} field={cta_link}>
          {children ? children : cta_text}
        </UPLink>
      )}
    </>
  );
};

export default CTAButton;
