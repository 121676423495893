import { PrismicRichText } from "@prismicio/react";

import { collapse, expand } from "./animation-helpers";
import { Disclosure } from "../disclosure/disclosure";

import type { KeyTextField, LinkField, RichTextField } from "@prismicio/client";
import type { ComponentType, MouseEvent, TransitionEvent } from "react";

function onTransitionEnd(e: TransitionEvent<HTMLDivElement>) {
  if (
    e.target instanceof HTMLElement &&
    e.target.getAttribute("data-expanded") === "true"
  ) {
    e.target.style.height = "auto";
  }
}

/**
 * CSS is required for `.accordion-button` and `.accordion-panel`
 * For animation `.accordion-panel` needs a height transition:
 * `transition: height 500ms cubic-bezier(0.19, 1, 0.22, 1);`
 */
export function Accordion({
  CTA,
  richTextComponents,
  slice,
}: {
  CTA: ComponentType<any>;
  richTextComponents: any;
  slice: {
    primary: {
      theme: string;
    };
    items: Array<{
      body: RichTextField;
      cta_classes?: KeyTextField;
      cta_link?: LinkField;
      cta_text?: KeyTextField;
      cta_variant?: string;
      heading: KeyTextField;
    }>;
  };
}) {
  return (
    <>
      {slice.items.map((item, index) => {
        return (
          <div key={index}>
            <Disclosure>
              {({ buttonProps, open, panelProps, toggle }) => (
                <>
                  <button
                    className="accordion-button"
                    onClick={(e: MouseEvent<HTMLButtonElement>) => {
                      if (e.target instanceof HTMLButtonElement) {
                        const t = e.target.nextElementSibling as HTMLElement;
                        open ? collapse(t) : expand(t);
                        toggle();
                      }
                    }}
                    {...buttonProps}
                  >
                    {item.heading}
                  </button>

                  <div
                    className="accordion-panel"
                    onTransitionEnd={onTransitionEnd}
                    style={{ height: "0" }}
                    {...panelProps}
                  >
                    <PrismicRichText
                      components={richTextComponents(slice.primary.theme)}
                      field={item.body}
                    />

                    {item.cta_text && (
                      <>
                        <br />
                        <CTA
                          className={item.cta_classes}
                          field={item.cta_link}
                          variant={item.cta_variant}
                        >
                          {item.cta_text}
                        </CTA>
                        <br />
                      </>
                    )}
                    <br />
                  </div>
                </>
              )}
            </Disclosure>
          </div>
        );
      })}
    </>
  );
}
