export default function Seal({ theme }: { theme: string }) {
  const src = theme.match(/blue/g) ? "/seal-white.png" : "/seal-black.png";
  return (
    // eslint-disable-next-line @next/next/no-img-element
    <img
      src={src}
      width="681"
      height="800"
      className="seal"
      alt="Georgetown University seal"
      aria-hidden="true"
      loading="lazy"
    />
  );
}
