/* eslint-disable no-case-declarations */
import { datadogLogs } from "@datadog/browser-logs";
import { asText, isFilled } from "@prismicio/client";
import {
  Accordion,
  ExampleWidget,
  VideoCarousel,
  VimeoPlayer,
  cycle,
  unstyledRichTextComponents,
} from "@up/ui";
import dynamic from "next/dynamic";
import { Fragment, useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";

// Dynamic import components as needed
const BasicColumn = dynamic(() => import("~/components/BasicColumn"));
const Comparison = dynamic(() => import("~/components/Comparison"));
const CohortListing = dynamic(() => import("~/components/CohortListing"));
const CourseListingItem = dynamic(() =>
  import("~/components/CourseListingItem"),
);
const Hero = dynamic(() => import("~/components/GeorgetownHero"));
const Testimonials = dynamic(() => import("~/components/Testimonials/index"));

import CTAButton from "~/components/CTAButton";
import RichText from "~/components/RichText";
import Seal from "~/components/Seal";
import Wrapper from "~/components/SliceWrapper";

const gridCols = function (data) {
  switch (data) {
    case 1:
    case "1":
      return "grid-cols-1";
    case 2:
    case "2":
      return "grid-cols-2";
    case 3:
    case "3":
      return "grid-cols-3";
    default:
      return "";
  }
};

const carouselHidden = function (videos) {
  if (videos.length === 0) {
    return "hidden";
  }

  if (videos.length === 1 && videos[0].hidden) {
    return "hidden";
  }

  return "";
};

const Fallback = function (message = "React error boundrary") {
  return function FallbackComponent({ error }) {
    useEffect(() => {
      console.info("FallbackComponent called");
      if (error) {
        datadogLogs.logger.error(message, {}, error);
      }
    }, [error]);

    return null;
  };
};

export default function SliceRender(
  prismicData,
  cohortData,
  reusableContentData,
  uid,
  isCoursePage = false,
) {
  return (
    prismicData &&
    prismicData.map((slice, mapIndex) => {
      const key = `${uid}-${mapIndex}`;

      switch (slice.slice_type) {
        case "accordion":
          const decoratedSlice = {
            ...slice,
            items: slice.items.map((item) => {
              return {
                ...item,
                heading: asText(item.heading)
              };
            })
          }
          return (
            <Wrapper
              key={key}
              data-analytics-component="accordion"
              slice={slice}
            >
              <div className="max-875 mx-auto">
                {slice.primary.category ? (
                  <div className="mb-4">
                    <RichText field={slice.primary.category} />
                  </div>
                ) : null}

                <div className="rte">
                  <Accordion
                    CTA={CTAButton}
                    richTextComponents={unstyledRichTextComponents}
                    slice={decoratedSlice}
                  />
                </div>
              </div>
            </Wrapper>
          );

        case "basic_column":
        case "columns":
          return (
            <Wrapper
              key={key}
              data-analytics-component="columns"
              slice={slice}
              cssClass={`${slice.primary.alt_headings ? "alt-headings" : ""}`}
            >
              {Boolean(slice.primary.heading.length) && (
                <div className="rte section-heading">
                  <RichText field={slice.primary.heading} />
                </div>
              )}
              <div
                className={`grid-mb-on-mobile gap-8 lg:grid ${gridCols(
                  slice.primary.column_count,
                )} type-${slice.primary.type || "default"} ${
                  slice.primary.flush_images ? "flush-images" : ""
                }`}
              >
                {slice.items.map((col, index) => {
                  return (
                    <BasicColumn
                      key={index}
                      data={col}
                      type={slice.primary.type}
                    />
                  );
                })}
              </div>
            </Wrapper>
          );

        case "cohort_listing":
          return (
            <div key={key}>
              <Wrapper data-analytics-component="cohort-listing" slice={slice}>
                <div className="rte section-heading">
                  <RichText field={slice.primary.heading} />
                </div>
                {cohortData.length ? (
                  <CohortListing
                    cohorts={cohortData}
                    slice={slice}
                    isCoursePage={isCoursePage}
                  />
                ) : (
                  <div className="pt-4 font-bold">
                    {isFilled.richText(slice.primary.empty_text) ? (
                      <RichText field={slice.primary.empty_text} />
                    ) : (
                      "We currently have no available cohorts. Please check back for more cohort options in the coming months."
                    )}
                  </div>
                )}
              </Wrapper>
            </div>
          );

        case "comparison":
          return (
            <Wrapper
              cssClass="comparison-slice"
              data-analytics-component="comparison"
              key={key}
              slice={slice}
            >
              <Comparison slice={slice} />
            </Wrapper>
          );

        case "course_listing":
          const col2 = [
            "theme-blue-medium",
            "theme-gray-medium",
            "theme-gray-medium",
            "theme-gray-light",
          ];
          const col3 = [
            "theme-blue-medium",
            "theme-gray-medium",
            "theme-gray-light",
          ];
          const themes = cycle(slice.items.length % 3 === 0 ? col3 : col2);
          return (
            <Wrapper
              key={key}
              data-analytics-component="course-listing"
              slice={slice}
            >
              <div className="rte section-heading">
                <RichText field={slice.primary.heading} />
              </div>
              <div
                className={`gap-8 lg:grid ${
                  slice.items.length % 3 === 0 ? "grid-cols-3" : "grid-cols-2"
                }`}
              >
                {slice.items.map((col, index) => {
                  const theme = themes(index + 1)[index];
                  return (
                    <CourseListingItem key={index} data={col} theme={theme} />
                  );
                })}
              </div>
            </Wrapper>
          );

        case "custom_code":
          return (
            <div
              key={key}
              data-analytics-component="custom-code"
              style={{ marginTop: "100px" }}
              dangerouslySetInnerHTML={{
                __html: `${slice.primary.markup[0].text}`,
              }}
            />
          );

        case "example_widget":
          return (
            <Wrapper
              key={key}
              data-analytics-component="example-widget"
              slice={slice}
              verticalPadding={false}
            >
              <ExampleWidget slice={slice} />
            </Wrapper>
          );

        case "hero":
          return <Hero key={key} slice={slice} />;

        case "reusable_content":
          const d = reusableContentData.find((item) => {
            return item.id === slice.primary.link.id;
          });
          return (
            <Fragment key={key}>
              {SliceRender(d.data.body, cohortData, reusableContentData, uid)}
            </Fragment>
          );

        case "rich_text":
          return (
            <Wrapper
              cssClass="rte slice-padding overflow-hidden relative"
              data-analytics-component="rich-text"
              key={key}
              slice={slice}
              verticalPadding={false}
            >
              {slice.primary.seal && <Seal theme={slice.primary.theme} />}
              <div
                className={`max-875 mx-auto ${
                  slice.primary.text_center ? "text-center" : ""
                }`}
              >
                <RichText field={slice.primary.text} />
                {slice.primary.cta_text && slice.primary.cta_link ? (
                  <div
                    className={`button-block ${
                      slice.primary.seal || slice.primary.text_center
                        ? "text-center"
                        : ""
                    }`}
                  >
                    <CTAButton field={slice.primary} />
                  </div>
                ) : null}
              </div>
            </Wrapper>
          );

        case "testimonial":
          return (
            <ErrorBoundary
              key={key}
              fallbackRender={Fallback("Testimonial slice error boundary")}
            >
              <Wrapper data-analytics-component="testimonial" slice={slice}>
                {slice.primary.heading && (
                  <div className="rte section-heading">
                    <RichText field={slice.primary.heading} />
                  </div>
                )}
                {slice.primary.video_id && (
                  <div
                    className="video-player-wrapper"
                    style={{
                      marginRight: "auto",
                      marginBottom: "3rem",
                      marginLeft: "auto",
                    }}
                  >
                    <div className="responsive-video">
                      <VimeoPlayer
                        autoplay={false}
                        id={slice.primary.video_id}
                        shouldLoad={true}
                      />
                    </div>
                  </div>
                )}
                <Testimonials items={slice.items} />
              </Wrapper>
            </ErrorBoundary>
          );

        case "video_carousel":
          return (
            <ErrorBoundary
              key={key}
              fallbackRender={Fallback("Carousel slice error boundary")}
            >
              <Wrapper
                slice={slice}
                data-analytics-component="video-carousel"
                wrapperClass={`video-carousel-wrapper ${carouselHidden(
                  slice.items,
                )}`}
              >
                <div className="rte section-heading">
                  <RichText field={slice.primary.heading} />
                </div>
                {slice.items.length ? (
                  <VideoCarousel
                    theme="white"
                    videos={slice.items}
                    VideoPlayerComponent={VimeoPlayer}
                  />
                ) : null}
              </Wrapper>
            </ErrorBoundary>
          );

        default:
          return <h1 key={key}>{slice.slice_type} is an unknown type!!!</h1>;
      }
    })
  );
}
