"use client";

import { useId, useState } from "react";

import type { ReactElement } from "react";

export interface DisclosureChildrenProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  toggle: () => void;
  buttonProps: Record<string, unknown>;
  panelProps: Record<string, unknown>;
}

export interface ToggleCallbackProps {
  isOpen: boolean;
}

export interface DisclosureProps {
  children: (props: DisclosureChildrenProps) => ReactElement;
}

export const Disclosure = function Disclosure(
  props: DisclosureProps,
): ReactElement {
  const [open, setOpen] = useState<boolean>(false);

  const panelID = `up-disclosure-${useId()}`;

  const buttonProps = {
    "aria-expanded": open ? "true" : "false",
    "aria-controls": panelID,
  };

  const panelProps = {
    "aria-hidden": open ? null : "true",
    id: panelID,
  };

  const toggle = function () {
    setOpen(!open);
  };

  return props.children({
    open,
    setOpen,
    toggle,
    buttonProps,
    panelProps,
  });
};
