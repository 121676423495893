import { getPaths, getProps } from "@up/data";
import { CanonicalLink, Spinner } from "@up/ui";
import Head from "next/head";
import { useRouter } from "next/router";

import Layout from "~/components/Layout";
import SliceRender from "~/components/SliceRender";

/*
  If you export an async function called getStaticPaths from a page that uses dynamic
  routes, Next.js will statically pre-render all the paths specified by getStaticPaths.

  https://nextjs.org/docs/basic-features/data-fetching#getstaticpaths-static-generation
*/
export async function getStaticPaths() {
  return getPaths((page) => {
    return !page.uid.match("college-credit");
  });
}

/*
  If you export an async function called getStaticProps from a page, Next.js will
  pre-render this page at build time using the props returned by getStaticProps.

  https://nextjs.org/docs/basic-features/data-fetching#getstaticprops-static-generation
*/

export async function getStaticProps(context) {
  return getProps(context);
}

const Page = function (props) {
  const desc = props.page.data?.description;
  const title = props.page.data?.title;
  const uid = props.uid;
  const catalogueID = props.page.data?.backoffice_catalogue_id;
  const courseName = props.page.data?.is_course_page ? props.uid : null;
  const router = useRouter();

  if (router.isFallback) {
    return (
      <div className="absolute inset-0 flex items-center justify-center">
        <Spinner /> Loading
      </div>
    );
  }

  const attentionBanner = {
    classes: props.page.data.attention_banner_classes,
    text:
      props.page.data.attention_banner_text || props.page.data.attention_banner,
    theme:
      props.page.data.attention_banner_theme ||
      props.page.data.attention_banner_color,
  };

  return (
    <Layout
      attentionBanner={attentionBanner}
      navigation={props.navigation}
      pageClasses={props.page.data.page_classes}
      preview={props.preview}
    >
      <CanonicalLink uid={props.uid} />
      <Head>
        {title && <title key="title">{title}</title>}
        {desc && <meta key="description" name="description" content={desc} />}
        {desc && (
          <meta key="og:description" property="og:description" content={desc} />
        )}
        {catalogueID ? (
          <meta
            key="catalogueid"
            name="catalogueid"
            id="catalogueid"
            content={catalogueID}
          />
        ) : null}
        {courseName ? (
          <meta
            key="coursename"
            name="coursename"
            id="coursename"
            content={courseName}
          />
        ) : null}
      </Head>
      {SliceRender(
        props.page.data.page_content,
        props.cohortData,
        props.reusableContentData,
        uid,
        Boolean(catalogueID),
      )}
    </Layout>
  );
};

export default Page;
