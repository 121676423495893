import Image from "next/image";
import { Fragment } from "react";

import { UPLink } from "./up-link";

export const unstyledRichTextComponents = function () {
  return {
    heading1({ children, key }) {
      return <h1 key={key}>{children}</h1>;
    },

    heading2({ children, key }) {
      return <h2 key={key}>{children}</h2>;
    },

    heading3({ children, key }) {
      return <h3 key={key}>{children}</h3>;
    },

    heading4({ children, key }) {
      return <h4 key={key}>{children}</h4>;
    },

    heading5({ children, key }) {
      return <h5 key={key}>{children}</h5>;
    },

    heading6({ children, key }) {
      return <h6 key={key}>{children}</h6>;
    },

    paragraph({ children, key }) {
      return <p key={key}>{children}</p>;
    },

    preformatted({ children, key }) {
      return <pre key={key}>{children}</pre>;
    },

    strong({ children, key }) {
      return <strong key={key}>{children}</strong>;
    },

    em({ children, key }) {
      return <em key={key}>{children}</em>;
    },

    listItem({ children, key }) {
      return <li key={key}>{children}</li>;
    },

    list({ children, key }) {
      return <ul key={key}>{children}</ul>;
    },

    oListItem({ children, key }) {
      return <li key={key}>{children}</li>;
    },

    oList({ children, key }) {
      return <ol key={key}>{children}</ol>;
    },

    image: ({ node, key }) => {
      const img = (
        <Image
          key={key}
          src={node.url}
          width={node.dimensions.width}
          height={node.dimensions.height}
          alt={node.alt || ""}
          quality={55}
          loading="lazy"
          sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
        />
      );

      return (
        <p key={key} className="block-img">
          {node.linkTo ? <UPLink field={node.linkTo}>{img}</UPLink> : img}
        </p>
      );
    },

    hyperlink: (props) => {
      const { node, children, key } = props;
      return (
        <UPLink
          key={key}
          field={node.data}
          rel={node.data?.target ? "noopener noreferrer" : null}
          target={node.data?.target ? node.data.target : "_self"}
        >
          {children}
        </UPLink>
      );
    },

    embed: ({ node, key }) => (
      <div
        key={key}
        data-oembed={node.oembed.embed_url}
        data-oembed-type={node.oembed.type}
        data-oembed-provider={node.oembed.provider_name}
        dangerouslySetInnerHTML={{ __html: node.oembed.html ?? "" }}
      />
    ),

    label: ({ node, children, key }) => (
      <span key={key} className={node.data.label}>
        {children}
      </span>
    ),

    span: (props) => {
      const { text, key } = props;
      const result = [];

      let i = 0;
      for (const line of text.split("\n")) {
        if (i > 0) {
          result.push(<br key={`${i}__break`} />);
        }

        result.push(<Fragment key={`${i}__line`}>{line}</Fragment>);

        i++;
      }

      return <Fragment key={key}>{result}</Fragment>;
    },
  };
};
