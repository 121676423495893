export function collapse(el: HTMLElement) {
  const elementTransition = el.style.transition;
  el.style.transition = "";

  requestAnimationFrame(function () {
    el.setAttribute("data-expanded", "false");
    el.style.height = el.scrollHeight + "px";
    el.style.transition = elementTransition;

    requestAnimationFrame(function () {
      el.style.height = 0 + "px";
    });
  });
}

export function expand(el: HTMLElement) {
  el.style.height = el.scrollHeight + "px";
  el.setAttribute("data-expanded", "true");
}
