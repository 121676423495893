export const modifyQueryParams = function (url: URL | string, params = {}) {
  const uri = typeof url === "string" ? new URL(url) : url;

  const currentParams = Object.fromEntries(
    new URLSearchParams(uri.searchParams),
  );

  return new URL(
    `${uri.origin}${uri.pathname}?${new URLSearchParams({
      ...currentParams,
      ...params,
    })}`,
  );
};
