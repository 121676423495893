"use client";

import { useEffect, useRef, useState } from "react";

const TARGET = "https://kaplan-labs.github.io";

export function ExampleWidget({ slice }) {
  const iframe = useRef(null);
  const [iframeHeight, setIframeHeight] = useState(400);

  const setupMessaging = function () {
    if (iframe && iframe.current) {
      iframe.current.contentWindow.postMessage("ping", TARGET);
    }
  };

  useEffect(
    function () {
      const handleMessage = function (event) {
        if (event.origin !== TARGET) {
          return;
        }

        if (
          typeof event.data === "object" &&
          event.data.type === "FRAME_HEIGHT"
        ) {
          setIframeHeight(event.data.height);
        }
      };

      setupMessaging();

      window.addEventListener("message", handleMessage);

      return function () {
        window.removeEventListener("message", handleMessage);
      };
    },
    [iframe],
  );

  return (
    <iframe
      onLoad={setupMessaging}
      ref={iframe}
      src={slice.primary.url}
      data-analytics-component="example-widget"
      style={{
        width: "100%",
        height: `${iframeHeight}px`,
        marginBottom: "2rem",
      }}
      title="example"
    />
  );
}
